import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LiveCamsCards01 from 'components/Software/iOS/LiveCams/liveCamsCard01';
import LiveCamsCards02 from 'components/Software/iOS/LiveCams/liveCamsCard02';
import LiveCamsCards03 from 'components/Software/iOS/LiveCams/liveCamsCard03';
import NavButtons from 'components/Software/iOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "iOS Software for your INSTAR IP Camera",
  "path": "/Software/iOS/LiveCams/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The iOS app LiveCams for your iPad or iPhone. Control your camera when you don´t have access to your computer.",
  "image": "./P_SearchThumb_LiveCams.png",
  "social": "/images/Search/P_SearchThumb_LiveCams.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS_LiveCams_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='iOS Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The iOS app LiveCams for your iPad or iPhone. Control your camera when you don´t have access to your computer.' image='/images/Search/P_SearchThumb_LiveCams.png' twitter='/images/Search/P_SearchThumb_LiveCams.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/iOS/LiveCams/' locationFR='/fr/Software/iOS/LiveCams/' crumbLabel="LiveCams" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ios",
        "aria-label": "ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iOS`}</h2>
    <h3 {...{
      "id": "livecams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#livecams",
        "aria-label": "livecams permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LiveCams`}</h3>
    <p>{`To access your IP camera with the iOS App `}<a href="http://eggmantechnologies.com/" target="_blank" rel="noopener noreferrer">{`LiveCams`}</a>{` we recommend that you first download the newest version from the app store. Use the search word `}<a href="https://itunes.apple.com/us/app/live-cams-pro-foscam-multi/id428145132?mt=8" target="_blank" rel="noopener noreferrer">{`LiveCams`}</a>{` to find the app inside the app store.`}</p>
    <h3 {...{
      "id": "add-a-camera-to-live-cams-ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera-to-live-cams-ios",
        "aria-label": "add a camera to live cams ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera to Live Cams (iOS)`}</h3>
    <p>{`Launch Live Cams Pro on your iPhone or iPad and wait until the “thumbnail screen” appears. This screen is intended to show one or more cameras at a time with a simple video display and no PTZ controls. It will be empty the first time you run the app. Click the + sign on the bottom tool bar to add a camera.`}</p>
    <LiveCamsCards01 mdxType="LiveCamsCards01" />
    <h4 {...{
      "id": "choose-your-camera-model-and-add-your-cameras-ip-port-and-your-personal-login",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#choose-your-camera-model-and-add-your-cameras-ip-port-and-your-personal-login",
        "aria-label": "choose your camera model and add your cameras ip port and your personal login permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Choose your camera model and add your camera´s IP, port and your personal login`}</h4>
    <p>{`Click the `}<strong parentName="p">{`Change`}</strong>{` button for the camera profile field and select the appropriate camera profile for your INSTAR Camera. Enter the camera details including the IP address or DNS host name, HTTP port and the login username/password for the camera. Press Save and if the camera does not connect or fails with some sort of error, tap and hold one finger on the error and wait for a menu to pop up. Select `}<strong parentName="p">{`Edit`}</strong>{` and try to adjust the information to correct any errors. You may need to try different user names and passwords or double-check that the correct camera profile is selected for your device model.`}</p>
    <LiveCamsCards02 mdxType="LiveCamsCards02" />
    <h4 {...{
      "id": "view-your-cameras-live-video-activate-the-audio-2-way-channel-and-start-local-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#view-your-cameras-live-video-activate-the-audio-2-way-channel-and-start-local-recordings",
        "aria-label": "view your cameras live video activate the audio 2 way channel and start local recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`View your cameras live video, activate the audio 2-way channel and start local recordings`}</h4>
    <p>{`If the camera loads on the thumbnail screen, the data is correct. You can single tap on the image to load the single-camera view along with audio/ptz controls and other options. If the camera fails to load and an error message appears, tap and hold on the error until a menu pops up, click “Edit” and then alter settings to try again.`}</p>
    <LiveCamsCards03 mdxType="LiveCamsCards03" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      